/* TransactionsSection.css */

/* Table container styles */
.transaction-table-container {
    overflow-x: auto;
}

/* Table styles */
.transaction-table {
    width: 100%;
    border-collapse: collapse;
}

.transaction-table th,
.transaction-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.transaction-table th {
    background-color: #4C337B;
    color: #fff;
}

.transaction-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.transaction-table tbody tr:hover {
    background-color: #ddd;
    cursor: pointer;
}
