/* NotificationsSection.css */

/* Notification container styles */
.notification-container {
    margin-top: 10px;
}

/* No notification message styles */
.no-notification-message {
    color: #666;
    font-style: italic;
}

/* Notification table styles */
.notification-table {
    width: 100%;
    border-collapse: collapse;
}

/* Table header styles */
.notification-table th {
    background-color: #4C337B;
    color: #fff;
    padding: 10px;
    border: 1px solid #ddd;
}

/* Table cell styles */
.notification-table td {
    padding: 10px;
    border: 1px solid #ddd;
}

/* Error notification styles */
.notification-table tr.error td {
    color: red;
}

/* Hover effect */
.notification-table tbody tr:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}
