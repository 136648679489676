/* Dashboard.css */

/* Additional styles for the Dashboard */
.main-container {
    padding: 20px;
    display: flex;
    justify-content: space-between;
}

.sidebar {
    width: 200px;
    background-color: #f0f0f0;
    padding: 20px;
}

.content-panel {
    flex: 1;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
