/* ContentPanel.css */

.content-panel {
    flex-grow: 1;
    padding: 20px;
    background-color: #fff;
    border-left: 1px solid #ccc;
}

.content-panel h2 {
    margin-bottom: 20px;
}
