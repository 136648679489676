/* Login.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    background-color: #f4f4f4;
}


.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.form-container {
    background-color: #fff;
    padding: 40px;
    /*border-radius: 10px;*/
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 320px;
}

h2 {
    text-align: center;
    margin-bottom: 30px;
}

label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

input[type="text"],
input[type="password"] {
    width: calc(100% - 20px);
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

.btn-login {
    width: 100%;
    padding: 12px;
    background-color: #4C337B;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-login:hover {
    background-color: #ED9E2E;
}

/* Responsive styles */
@media screen and (max-width: 600px) {
    .form-container {
        width: 80%;
    }
}
