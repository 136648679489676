/* Header.css */

.header {
    background-color: #4C337B;
    color: white;
    padding: 1rem;
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.left-section {
    display: flex;
    align-items: center;
}

.title {
    font-size: 1.5rem;
    cursor: pointer;
}

.user-info {
    display: flex;
    align-items: center;
}

.greeting {
    margin-right: 1rem; /* Add margin to separate greeting from user icon */
    white-space: nowrap;
}

.user-icon {
    font-size: 1.2rem;
    margin-right: 0.5rem;
}

.right-section {
    display: flex;
    align-items: center;
}

.logout-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.2rem;
    margin-left: 1rem; /* Add margin to separate logout button from user info */
    transition: opacity 0.3s ease;
    padding: 10px;
    border-radius: 5px;
}

.logout-button:hover {
    opacity: 0.8;
    background-color: #ED9E2E;
}
