/* Sidebar.css */

.sidebar {
    width: 200px;
    background-color: #4C337B;
    color: #000000;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar li {
    padding: 10px;
    cursor: pointer;
}

.sidebar li:hover {
    background-color: #6F4A9D;
    color: #ffffff;
}
