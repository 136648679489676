/* AboutSection.css */

.about-section {
    background-color: #f8f9fa; /* Light gray background */
    padding: 20px;
}

.container {
    max-width: 600px;
    margin: 0 auto;
}

h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.about-text {
    font-size: 16px;
    margin-bottom: 20px;
}

.logo-container {
    text-align: center;
}

.logo {
    max-width: 200px;
    height: auto;
}

.footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.date,
.divider,
.website-link {
    font-size: 12px;
}

.divider {
    margin: 0 5px;
}

.website-link {
    color: #007bff; /* Blue color for the link */
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    font-size: inherit;
}

.copyright {
    font-size: 12px;
    margin-left: 5px;
    display: flex;
    justify-content: center;
}
