/* DashboardSection.css */
.dashboard-section {
    margin-top: 20px;
}

.dashboard-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around; /* Adjusted to evenly distribute cards */
}

.dashboard-card {
    width: calc(33.33% - 20px); /* Adjusted width calculation */
    max-width: 300px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
}

.dashboard-card h3 {
    margin-bottom: 10px;
    color: #333;
}

.dashboard-card p {
    margin: 0;
    color: #666;
}
