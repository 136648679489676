/* MetersSection.css */

/* Container styles */
.meters-section {
    padding: 20px;
}

/* Title styles */
h2 {
    margin-bottom: 20px;
}

/* Add meter button styles */
.add-meter {
    margin-bottom: 20px;
}

.add-meter-button {
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #4C337B;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: auto;
}

.add-meter-button:hover {
    background-color: #ED9E2E;
}

.add-meter-button svg {
    margin-right: 5px;
}

/* Table container styles */
.meter-table-container {
    overflow-x: auto;
}

/* Table styles */
.meter-table {
    width: 100%;
    border-collapse: collapse;
}

.meter-table th,
.meter-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.meter-table th {
    background-color: #4C337B;
    color: #fff;
}

.meter-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.meter-table tbody tr:hover {
    background-color: #ddd;
    cursor: pointer;
}