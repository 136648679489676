/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 400px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.modal-header h3 {
    margin: 0;
}

.close-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #999;
    padding: 0;
    margin: 0;
    width: auto;
}

.close-button:hover {
    background: none;
}

.modal-body {
    padding-bottom: 20px;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
}

.save-button,
.delete-button,
.update-button {
    background-color: #4C337B;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    margin-left: 10px;
    transition: background-color 0.3s ease;
}

.save-button:hover,
.delete-button:hover,
.update-button:hover {
    background-color: #6D4AAB;
}

