#meterNumber {
/*   customize this select box to look good*/
    width: auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 10px;
    font: inherit;
    background-color: #f8f8f8;
}