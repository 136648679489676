.report-options {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.report-select {
    width: 200px;
}

#start-date,
#end-date {
    width: 200px;
}

.report-options button {
    width: 100px;
    height: 40px;
    background-color: #4C337B;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.report-options button:hover {
    background-color: #6D5A8D;
}
