/* UsersSection.css */

/* Table container styles */
.user-table-container {
    overflow-x: auto;
}

/* Table styles */
.user-table {
    width: 100%;
    border-collapse: collapse;
}

.user-table th,
.user-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.user-table th {
    background-color: #4C337B;
    color: #fff;
}

.user-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.user-table tbody tr:hover {
    background-color: #ddd;
    cursor: pointer;
}

/* User status styles */
.user-status {
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    width: auto;
    text-align: center;
    font-weight: bold;
}

.active {
    background-color: green;
    color: white;
}

.inactive {
    background-color: red;
    color: white;
}
